import React from "react"
import { Container, Row, Col, Badge } from "reactstrap"

import Layout from "src/components/Layout"
import Seo from "src/components/Seo"
import Heading from "src/components/index/Heading"
import StyledIllustration from "src/components/features/StyledIllustration.jsx"

import padlock from "src/assets/img/graphics/padlock.svg"
import chooseIllustration from "src/assets/img/icons/undraw/choose.svg"
import alertIllustration from "src/assets/img/icons/undraw/alert-gradient.svg"

const FeaturePracticeManagement = () => {
  return (
    <Layout>
      <Seo
        title="Carebit: Security"
        pathname="/security"
        description="Learn how Carebit protects your data using encryption and other security best practices."
      />
      <div className="u-centerFlex u-textCenter Hero">
        <Container>
          <Row>
            <Col
              lg={{ size: 6, offset: 3 }}
              md={{ size: 10, offset: 1 }}
              style={{ zIndex: 2, padding: "120px 40px 200px", color: "white" }}
            >
              <Badge color="warning" className="u-marginBottom20">
                Privacy & security
              </Badge>
              <h1>Security</h1>
              <p className="u-whiteTranslucent">
                Carebit is built to be secure by default. State-of-the-art,
                bank-grade SSL encryption is used throughout, while other best
                practices such as IP whitelisting, private networks, VPNs and
                automated checks further strengthen Carebit’s security.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container>
          <Row>
            <Col md={{ size: 10, offset: 1 }}>
              <section>
                <Row className="u-centerFlex">
                  <Col lg={6} md={12} className="SplitContentRow-text">
                    <Row>
                      <Heading title="Always encrypted">
                        256-bit SSL encryption throughout
                      </Heading>
                    </Row>
                    <p className="u-midGrey">
                      Carebit’s routing system ensures that any data being
                      accessed or uploaded only travels via an encrypted SSL
                      connection between the user and the Carebit servers. This
                      is the same standard as major banks use, and all Carebit’s
                      data centres are located in London and certified to ISO
                      27001 standard.
                    </p>
                  </Col>
                  <Col lg={6} md={12} className="SplitContentRow-graphic">
                    <StyledIllustration src={padlock} />
                  </Col>
                </Row>
              </section>

              <section>
                <Row className="u-centerFlex">
                  <Col lg={6} md={12} className="SplitContentRow-graphic">
                    <StyledIllustration src={chooseIllustration} />
                  </Col>
                  <Col lg={6} md={12} className="SplitContentRow-text">
                    <Row>
                      <Heading title="Precision permissions">
                        Restricting access to those with the correct permissions
                      </Heading>
                    </Row>
                    <p className="u-midGrey">
                      Carebit employs a sophisticated system of permissions to
                      control who can see what. For example, Clinic A can only
                      see its own patients, letters, invoices and letter
                      templates, but Clinic B can also see Clinic A’s patient
                      letters only if Clinic A has shared them with Clinic B.
                      This is all backed up by a suite of automated checks to
                      ensure complete privacy at all times.
                    </p>
                  </Col>
                </Row>
              </section>

              <section>
                <Row className="u-centerFlex">
                  <Col lg={6} md={12} className="SplitContentRow-text">
                    <Row>
                      <Heading title="Limiting connections">
                        IP whitelisting
                      </Heading>
                    </Row>
                    <p className="u-midGrey">
                      Connections to the Carebit system are strictly limited.
                      Only connections from specific IP addresses and networks
                      are permitted to connect to Carebit systems, ensuring that
                      access is controlled at all times.
                    </p>
                  </Col>
                  <Col lg={6} md={12} className="SplitContentRow-graphic">
                    <StyledIllustration src={alertIllustration} />
                  </Col>
                </Row>
              </section>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default FeaturePracticeManagement
